@import url("https://fonts.googleapis.com/css?family=Raleway:400");

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
:root {
  --main-color: #006174;
  --main-color-opacity: #00617477;
  --second-color: #ffcc00;
}

html,
body {
  height: 100%;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}


.phonebox {
	position: relative;
	z-index: 0;
	border-radius: 42px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: sans-serif;
	font-weight: bold;

	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #ffffff;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#9f0285, #f9f9f9), linear-gradient(#f9f9f9, #6525cb);
		animation: rotate 2s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 1px;
		top: 1px;
		width: calc(100% - 3px);
		height: calc(100% - 3px);
		background: white;
		border-radius: 42px;
	}
}



.full-height {
  height: 100%;
}

.anima {
  height: 120vh;
  color: #fff;
  background: linear-gradient(-45deg, #47c42b, #12b199, #2275c9, #04394f, #14a0b2 );
  background-size: 400% 400%;
  /*-webkit-animation: Gradient 60s ease infinite;
  -moz-animation: Gradient 60s ease infinite;
  animation: Gradient 60s ease infinite;*/
  }

.anima2 {
    color: #fff;
    background: linear-gradient(-45deg, #47c42b, #12b199, #2275c9, #04394f, #14a0b2);
    background-size: 400% 400%;
    /*-webkit-animation: Gradient 25s ease infinite;
    -moz-animation: Gradient 25s ease infinite;
    animation: Gradient 25s ease infinite;*/
    }

.paused {
  -webkit-animation-play-state: paused; /* Safari 4.0 - 8.0 */
  animation-play-state: paused;
}

@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

h1,
h6 {
  font-family: 'Open Sans';
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 45%;
  right: 0;
  left: 0;
  }

/*button {
  font-family: 'Open Sans';
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 48%;
}*/



.animation-blog {
  cursor: pointer;
  transition: all 0.2s ease 0s;
  outline: none;
}

.animation-blog:hover {
  transform: translateY(-5px);
}

.animation-blog:active {
  transform: translateY(-1px);
}

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showInUp2 {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showInRight {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes showInLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes showInLeft2 {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes messageAfterShow {
  0% {
    transform: scale(100%);
    opacity: 1;
  }
  60% {
    transform: scale(135%);
    opacity: 0.8;
  }

  100% {
    transform: translateX(-200%);
    opacity: 0;
  }
}

@keyframes clickLink {
  0% {
    transform: scale(100%);
  }

  50% {
    transform: scale(89%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes clickBtnEffect {
  0% {
    transform: scale(100%);
    background-color: #3F51B5;
    color: #0f172a;
  }

  50% {
    transform: scale(89%);
    background-color: #3F51B5;
    color: #f1f5f9;
  }
  100% {
    transform: scale(100%);
    background-color: #3F51B5;
    color: #f1f5f9;
  }
}

@keyframes clickBtnEffect2 {
  0% {
    transform: scale(100%);
    background-color: transparent;
    color: #84cc16;
    border: 1px solid #84cc16;
  }

  50% {
    transform: scale(89%);
    background-color: #84cc16;
    color: #fff;
    border: none;
  }
  100% {
    transform: scale(100%);
    background-color: #84cc16;
    color: #fff;
    border: none;
  }
}
